a.button, button.button {
  color: $generalFontColor;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.16em;
  font-weight: 400;
  text-align: center;
  transform: scale(1,1);
  transition: all 0.5s ease-in-out;
  &.button-primary {
    color: $buttonTextColor;
    background-color: $buttonPrimaryBackground;
    border-radius: 128px;
    padding: 1.8rem 3rem;
    text-decoration: none;
    letter-spacing: 0.02em;
    line-height: 1.25em;
    &:hover {
      background-color: $buttonPrimaryBackgroundHover;
    }
  }
  &.button-secondary {
    color: $darkColorBackground !important;
    background-color: $primaryColorReverse;
    font-size: 1.5rem;
    padding: 1.1em 2.3em;
    border-radius: 128px;
    line-height: 1.25em;
    &:hover {
      background-color: lighten($primaryColorReverse, 5%);
    }
    @media only screen and (min-width: 1024px) {
      font-size: 1.6rem;
      padding: 1em 2em;
    }
  }
  &.button-dark {
    color: white !important;
    background-color: $darkColorBackground;
    padding: 1.1em 2.3em;
    border-radius: 128px;
    line-height: 1.25em;
    &:hover {
      background-color: lighten($darkColorBackground, 5%);
    }
  }
  &.button-mobile {
    color: $WhiteBackground !important;
    background-color: inherit;
    font-size: 2rem;
    padding: 0.8em 2.5em;
    width: 100%;
    height: 100%;
    line-height: 1em;
    position: relative;
    &:hover {
      background-color: inherit;
      color: black !important;
    }
  }
  &:hover {
    transform: scale(1.05,1.05);
    transition: all 0.5s ease-in-out;
  }
}
