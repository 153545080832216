section.section {
  min-height: 85vh;
  //overflow: auto; // bug fix
  overflow: hidden; // bug fix
  //margin-top: -70px;
  //transition: all 3s 1s;

  h1, h2 {
    margin-top: -20px;
    margin-bottom: 55px;

    @media only screen and (min-width: 1140px) {
      margin-top: 0;
    }
  }

  &.section-invert { // sections avec text clair sur fond foncé
    color: white;
    text-shadow: none;
    background-color: $darkColorBackground;

    h1, h2 {
      color: $primaryColorReverse;
      text-shadow: none;
    }
    .button {
      color: white;
    }
  }
  &.section-color { // sections avec text clair sur fond foncé
    color: white;
    text-shadow: none;
    background-color: $colorBackground;

    h1, h2 {
      color: $primaryColorReverse;
      text-shadow: none;
    }
    .button {
      color: white;
    }
  }
  &.section-light { // sections avec text clair sur fond foncé
    color: $darkColorBackground;
    text-shadow: none;
    background-color: $LightBackground;

    h1, h2 {
      color: $darkColorBackground;
      text-shadow: none;
    }
    .button {
      color: white;
    }
  }
  &.section-white { // sections avec text clair sur fond foncé
    color: $darkColorBackground;
    text-shadow: none;
    background-color: $WhiteBackground;

    h1, h2 {
      color: $darkColorBackground;
      text-shadow: none;
    }
    .button {
      color: white;
    }
  }

  &.leftdown {
    clip-path: polygon(0 2%, 100% 0, 100% 100%, 0% 98%);
    @media only screen and (min-width: 768px) {
      clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 95%);
    }
  }
  &.leftupdown {
    clip-path: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
    @media only screen and (min-width: 768px) {
      clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
    }
  }
  &.rightdown {
    clip-path: polygon(0 0%, 100% 2%, 100% 98%, 0 100%);
    @media only screen and (min-width: 768px) {
      clip-path: polygon(0 0%, 100% 5%, 100% 95%, 0 100%);
    }
  }
  &.rightupdown {
    clip-path: polygon(0 0%, 100% 2%, 100% 100%, 0 100%);
    @media only screen and (min-width: 768px) {
      clip-path: polygon(0 0%, 100% 5%, 100% 100%, 0 100%);
    }
  }

  .portfolio {
    display: block;

    &.first {
      clear: left;
    }

    img {
      width: 100%;
      height: auto;
      transition: all 1s 0.5s;
      max-width: 210px;
      &:hover {
        transform: scale(1.3, 1.3);
        transition: all 0.5s 0.2s;
      }
    }

    @media only screen and (min-width: 768px) {
      margin-bottom: 60px;
    }
  }

  .servicezone {
    display: flex;
    justify-items: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    .gridbox {
      display: flex;
      justify-items: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 35px 0 150px 0;
      position: relative;
      min-width: 225px;

      .feature {
        margin-bottom: 30px;

        .description {
          @media only screen and (min-width: 768px) {
            min-height: 75px;
          }
          @media only screen and (min-width: 1024px) {
            min-height: 0;
          }
        }
      }

      a.button.button-secondary, button.button.button-secondary {
        position: relative;
      }

      @media only screen and (min-width: 768px) {
        margin: 35px 15px 150px 15px;
        .desctext {
          margin-bottom: 25px;
          font-size: 15px;
          min-height: 116px;
        }
        a.button.button-secondary, button.button.button-secondary {
          font-size: 1.25rem;
          //position: absolute;
          bottom: 0;
        }
      }
    }
    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .odddpt {
    background-color: rgba(80, 130, 135, 0.55);
    padding: 20px;
    height: 100%;
    padding-bottom: 2em;
    //margin-bottom: -55px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    .boxapart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;

      #otherf {
      }
      .icon {
        float: none !important;
        display: block;
        &.horiz {
          margin-bottom: 10px;
        }
      }
      .description {
        &.horiz {
          float: none !important;
          margin: 0;
        }
      }
      p {
        font-size: 1.5em;
        float: none !important;
        position: relative;
        margin-bottom: 15px;

        &.horiz {
          text-align: center;
          margin-bottom: 40px;
        }
      }
      a.button-secondary {
        float: none !important;
        width: 100%;
        @media only screen and (min-width: 768px) {
          font-size: 1.25rem;
        }
      }
      @media only screen and (min-width: 768px) {
        font-size: 15px;
      }
    }

    @media only screen and (min-width: 320px) {
      display: block;
      padding-bottom: 12em;
    }
    @media only screen and (min-width: 768px) {
      margin-bottom: -60px;
    }
  }

  .pictbox {

    img {
      width: 100%;
      height: auto;

      @media only screen and (min-width: 768px) {
        margin-top: 10px;
      }
    }

    @media only screen and (min-width: 1140px) {
      min-height: 400px;
    }
  }

  &.section-punch {
    h1, h2 {
      margin-top: -112px;
    }
  }

  @media only screen and (min-width: 768px) {
    //margin-top: -70px;
  }
  @media only screen and (min-width: 1140px) {
    //margin-top: -110px;
  }

  &#about {
    a {
      color: rgba(255,255,255,1);
      font-weight: 600;
      text-decoration: none;

      &:hover {
        color: rgba(255,255,255,0.7);
        text-decoration: underline;
      }
    }
  }

}

@mixin sectionFlex() {
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 10em 0;
  // Hack IE 11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
    flex: inherit;
  }
}

@import 'sections/punchline';
@import 'sections/media';
@import 'sections/video';
@import 'sections/features';
@import 'sections/information';
@import 'sections/portfolio';
