
.short-animate {
  transition: .5s ease-in-out;
}

.long-animate {
  transition: .5s .5s ease-in-out;
}

.lightbox-wrapper, .lightbox-wrapper_pf {
  position: fixed;
  top: -100vh;
  bottom: 100vh;
  left: 0;
  right: 0;
  background: $lightboxCTAbackground;
  z-index: 501;
  opacity: 0;
  &:target {
    top: 0%;
    bottom: 0%;
    opacity: 1;
    ~ #lightbox-controls {
      top: 0px;
    }
    ~ #lightbox-controls #close-lightbox:after {
      width: 50px;
    }
    ~ #lightbox-controls #close-lightbox:before {
      height: 50px;
    }
  }
  .lightbox-content {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateX(0);

    overflow-x: scroll;
    padding-bottom: 60px;

    img.imgportfolio {
      width: 100%;
      margin-top: 370px;

      @media only screen and (min-width: 768px) {
        margin-top: 90px !important;
      }
    }

    h2 {
      font-size: 36px;
      margin-bottom: 0;
    }

    h5 {
      font-size: 2rem !important;
      font-weight: 300;
      margin-top: 10px;
    }

    p {
      font-size: 16px;
      line-height: 1.5em;
    }

    h2, p {
      color: $WhiteBackground;
    }
  }
  a {
    color: white;
  }

  .technos {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .technologo {
      display: grid;
      margin: 50px 15px;

      img {
        filter: grayscale(1);

        &:hover {
          transition: all .2s ease-in-out;
          filter: none;
        }
      }
    }
  }
  a.button.button-primary, button.button.button-primary {
    color: white;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 128px;
    padding: 1.8rem 3rem;
    text-decoration: none;
    letter-spacing: .02em;
    line-height: 1.25em;
    margin: 20px;

    &:hover {
      color: $darkColorBackground;
      background-color: white;
    }
  }
}

.lightbox-wrapper_pf {
  position: fixed;
  top: -100vh;
  bottom: 100vh;
  left: 0;
  right: 0;
  background: $lightboxPFbackground;
  z-index: 501;
  opacity: 0;
  &:target {
    top: 0%;
    bottom: 0%;
    opacity: 1;
    ~ #lightbox-controls {
      top: 0px;
    }
    ~ #lightbox-controls #close-lightbox:after {
      width: 50px;
    }
    ~ #lightbox-controls #close-lightbox:before {
      height: 50px;
    }
  }
  .lightbox-content {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateX(0);
  }
  a {
    color: white;
  }
}

#lightbox-controls {
  position: fixed;
  height: 70px;
  width: 70px;
  top: -70px;
  right: 0;
  z-index: 502;
  background: rgba(0, 0, 0, .1);
}

#close-lightbox {
  display: block;
  position: absolute;
  overflow: hidden;
  height: 50px;
  width: 50px;
  text-indent: -5000px;
  right: 10px;
  top: 10px;
  transform: rotate(45deg);
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    background: white;
    border-radius: 2px;
  }
  &:before {
    height: 0px;
    width: 3px;
    left: 24px;
    top: 0;
    transition: .5s .5s ease-in-out;
  }
  &:after {
    width: 0px;
    height: 3px;
    top: 24px;
    left: 0;
    transition: .5s 1s ease-in-out;
  }
}
.flexAlign {
  display: flex;
  flex-direction: row;
  align-items: center;
}
