section.section.section-features {
  @include sectionFlex();
  min-height: 40vh;
  text-align: center;
  padding-bottom: 70px;

  .shade {
    display: none;

    @media only screen and (min-width: 768px) {
      display: block;
      width: 200%;
      background-image: url(/assets/img/shade.png);
      background-repeat: no-repeat;
      background-position: 100%;
      background-size: 100%;
      position: absolute;
      height: 100px;
      bottom: 7.9em;
      z-index: -1;
      left: -500px;
      opacity: 0.14;
    }
  }

  .productbox {
    margin: 0;

    .productpict {
      img {
        width: 70%;

        @media only screen and (min-width: 1140px) {
          width: 60%;
        }
      }
    }
    .productline {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 8.5em;

      h4 {
        font-size: 1.4em;
        line-height: 1.45em;
        text-transform: uppercase;
        font-weight: 600;
      }

      p {
        width: 100%;
        font-size: 1.3em;
        margin-bottom: 20px;
      }
      img {
        margin-top: 0;
        width: 60%;
        height: auto;

        @media only screen and (min-width: 768px) {
          margin-top: 30px !important;
          width: 100% !important;
        }
        @media only screen and (min-width: 1140px) {
          margin-top: 10px !important;
        }
      }
      @media only screen and (min-width: 768px) {
        align-items: left;
        justify-content: left;
      }
    }
    @media only screen and (min-width: 1140px) {
      .productpict img {
        width: 100%;
      }
    }
  }

  &#print .productbox {
    margin: 0 0 150px 0;
  }

  .feature {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3.5em;

    h4 {
      width: 100%;
      font-size: 1.5em;
      line-height: 1.45em;
      text-transform: uppercase;
      font-weight: 600;
      margin-top: 0;
    }

    p {
      width: 100%;
      font-size: 1.5em;

      @media only screen and (min-width: 480px) {
        font-size: 1.3em;
      }
    }

    ul {
      padding-left: 8px;
      width: 100%;

      li {
        margin: 0 0 31px;
        position: relative;
        padding-left: 13px;
        list-style: none;
        z-index: 1;

        &:before {
          transition: .5s ease-in-out;
          position: absolute;
          content: "• ";
          color: transparent;
          border: 2px solid rgba(255, 255, 255, 0.17);
          font-size: 14px;
          left: 0;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          top: -13px;
          z-index: -1;
          background-color: #f4f4f4;
        }

        &:hover {

          &:before {
            transition: .5s ease-in-out;
            position: absolute;
            content: "• ";
            color: transparent;
            border: 2px solid rgba(255, 255, 255, 0.33);
            font-size: 14px;
            left: 0;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            top: -13px;
            z-index: -1;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin-bottom: 5em;
      .desctext {
        min-height: 150px;
      }
    }
    @media only screen and (min-width: 1024px) {
      margin-bottom: 8.5em;
    }
  }

  h4 {
    margin-bottom: 24px;

    @media only screen and (min-width: 1140px) {
      margin-bottom: 0;
    }
  }

  i.fa {
    font-size: 3em;
  }

  .icon {
    transition: all .5s .3s ease-in-out;
    width: 75px;
    &.horiz {
      float: left;
      margin-bottom: 100px;
    }
  }

  .description {
    font-size: 1.24em;
    text-transform: uppercase;
    margin-top: 0.5em;

    &.horiz {
      float: left;
      margin-left: 10px;
      margin: 12px 12px;
    }

    @media only screen and (min-width: 768px) {
      font-size: 1.25em;
      margin-top: 15px;
      margin-left: 0;
    }
  }

  p {
    font-weight: 200;
    font-size: 1.5em;
    line-height: 1.45em;

    @media only screen and (min-width: 480px) {
      font-size: 1.3em;
    }
  }

  a {
    width: 100%;
    text-decoration: none;
    transition: all .5s .3s ease-in-out;

    .description, p {
      color: $primaryColorReverse;
      transition: all .5s .3s ease-in-out;
    }
    &:hover {
      .description, p {
        color: darken($primaryColorReverse, 10%);
        transition: all .5s .3s ease-in-out;
      }

      span.icon {
        transform: scale(1.1, 1.1);
        opacity: 0.9;
        transition: all .5s .3s ease-in-out;
      }
    }
  }

  @media only screen and (min-width: $mobileBreakpoint) {
    i.fa {
      font-size: 4em;
    }
    .description {
      max-width: 300px;
      margin-top: 0.4em;
      margin-left: 0;
      font-size: 1.3em;
    }
  }
}
