.main {
  .section:first-of-type {
      margin-top: $headerHeightMobile;
      height: calc( auto - #{$headerHeightMobile} );
      @media only screen and (min-width:$mobileBreakpoint) {
          margin-top: 0;
          height: calc( 100vh - #{$headerHeight} );
          min-height: 100vh;
      }
  }
  @media only screen and (min-width: 768px) {
    .main .section:first-of-type {
      margin-top: 0;
      height: 100vh;
    }
  }
}
