section.section.section-info{
  @include sectionFlex();
  flex-direction: column;
  text-align: left;
  background-image: url("/assets/img/bkg_about.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 40vh;
  .buttonWrapper{
    margin-top:4em;
  }
  h1 {
    margin-top: 70px;
    margin-bottom: 0;
  }
  h2 {
    font-size: 2.5em;
    margin-top: 20px;
    margin-bottom: 0;
  }
  h1, h2, h3, h4, p {
    color: white;
  }
}
