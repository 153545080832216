section.section.section-punch {
  @include sectionFlex();
  flex-direction: column;
  text-align: left;
  background-image: url("/assets/img/bkg_splash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  @media only screen and (min-width: 480px) {
    @include sectionFlex();
    margin-top: -100px;
  }
  @media only screen and (min-width: 1140px) {
    @include sectionFlex();
    background-position: 100%;
    h4 {
      margin: 0 0 75px;
    }
  }
  h1 {
    color: white;
    text-shadow: $textShadow;
  }
  h2 {
    margin-top: 25px;

    span {
      color: $primaryColor;
      font-size: 1.2em;
    }
  }
  h1, h2 {
    color: white !important;
    font-size: 2.5rem;
    @media only screen and (min-width: 768px) {
      margin-top: 0;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 4rem;
      line-height: 1.2em;
      margin-top: 0;
    }
  }
  h4 {
    font-size: 2em;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 400;
    margin-bottom: 60px;
  }
  h1, h2, h3, h4 {
    text-align: center;
    line-height: 1.32em;
    @media only screen and (min-width: 700px) {
      text-align: left !important;
    }
  }

  .button-primary {
    margin-left: 20px;
  }

  //dotted punch
  .maintag {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    .special_anouncement {
      text-align: center;
      display: flex;
      align-items: center;
      justify-items: center;
      flex-direction: column;
      margin: 5px;
      padding: 5px;

      border-radius: 50%;
      width: 300px;
      height: 300px;

      border-style: dotted;
      border-color: rgba(255, 255, 255, 0.7);
      border-image-source: asset_url('dot.svg');
      border-image-slice: 33% 33%;
      border-image-repeat: round;

      .insideround {
        font-weight: 300;
        text-decoration: none;
        width: 100%;
        height: 100%;
        border: 2px solid rgba(255, 255, 255, 0.7);
        border-radius: 50%;
        transition: all .2s ease-in-out .2s;

        display: flex;
        flex-direction: column;
        padding: 43px;
        cursor: pointer;

        span {
          color: rgba(255, 255, 255, 0.7);
          position: relative;
          font-size: 2.2em;
          line-height: 1.25em;
          text-transform: uppercase;
          margin: 6px 0;

          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-top: 12px;
          }

          img {
            width: 65%;
          }

          &.logoreal {
            height: 50px;
          }
        }
        .button-primary {
          text-transform: uppercase;
          font-size: 1em;
          color: rgba(255, 255, 255, 0.7);
          background-color: transparent;
          border: 1px solid rgba(255, 255, 255, 0.7);
          border-radius: 128px;
          padding: 1rem 3rem;
          text-decoration: none;
          letter-spacing: 0.02em;
          line-height: 1.25em;
          transition: all .2s ease-in-out .2s;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.15);
          .button-primary {
            transition: all .2s ease-in-out .2s;
            color: $darkColorBackground;
            background-color: rgba(255, 255, 255, 1);
          }
        }
        &:focus, &:visited, &.active {
          border-radius: 50%;
        }
      }

      &.dotted {
        padding: 8px;
        border-width: 8px;
        border-image: url(/assets/img/dot.svg) 30 repeat stretch;
      }
      @media only screen and (min-width: 1024px) {
        margin: 20px;
      }
    }
    @media only screen and (min-width: 700px) {
      align-items: left;
      justify-items: left;
      margin: 0 0 45px 0;
    }
  }
}
