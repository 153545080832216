.header {
  .menu {
    position: absolute;
    right: 0;
    display: none;
    top: 10px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;

      li {
        float: left;
        margin: 0 8px;

        @media screen and (min-width: 1200px) {
          margin: 0 20px;
        }

        a {
          color: white;
          text-transform: uppercase;
          display: block;
          text-align: center;
          padding: 14px 0px;
          text-decoration: none;
          font-family: $titleFontFamily;
          font-size: 1em;
          font-weight: 300;
          letter-spacing: 0.02em;

          @media screen and (min-width: 1200px) {
            font-size: 1.16em;
          }

          &:hover {
            color: darken($primaryColorReverse, 20%);
          }

          &.phoneCall {
            font-size: 2em;
            font-weight: 700;
            line-height: 0.9em;
            cursor: default;

            &:hover {
              color: #fff;
            }
          }
        }
        .button-primary {
          font-weight: 400;
          font-size: 1.16em;
          text-shadow: none;
          border-radius: 2em;
          padding: 9px 26px;
          text-align: center;
          margin-top: 5px;
          letter-spacing: 0.02em;
          color: black;
          background-color: $primaryColor;
          border: 1px solid $primaryColor;

          &:hover {
            color: black;
            //background-color: darken($primaryColor, 20%);
            //border: 1px solid darken($primaryColor, 20%);
          }
        }
      }
    }
  }
}

.header.fixed {
  .menu {

    ul {

      li {

        a {
          color: $darkColorBackground;

          &:hover {
            color: lighten($darkColorBackground, 20%);
          }

          &.phoneCall {

            &:hover {
              color: #3b3d36;
            }
          }
        }
        .button-primary {
          color: black;

          &:hover {
            color: white;
          }
        }
        .button-folder {
          color: $primaryColor;

          &:hover {
            color: white;
          }
        }
      }
    }
  }
}
