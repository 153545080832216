.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 60px 20px;
  margin-bottom: $ctaFooterHeight;
  color: white;
  background-color: $footerBackground;

  h4 {
    margin-bottom: 0;

    a {
      color: #fff;
      text-decoration: none;
      cursor: default;
    }
  }

  p {
    margin-top: 5px;
  }

  .contacts {
    width: 100%;
    .logo {
      float: left;
      width: 50%;
      margin-top: 4px;
      img {
        height: 28px;
      }
    }
    .socialfooter {
      width: 50%;
      float: left;
      text-align: right;
      a, a img {
        color: #fff;
        fill: #fff;
        width: 30px;
        height: 30px;
        margin-left: 3px;
      }
    }
    .logo-sec {
      position: relative;
      width: 100%;
      float: left;
      .mention {
        font-size: 9px;
        font-weight: 100;
        display: inline-block;
        //float: left;
        margin-left: 12px;
        position: relative;
        bottom: -6px;

        text-align: center;
        width: 100%;
        margin-top: 30px;
      }
      a {
        float: left;
      }
      img {
        height: 28px;
      }
      @media only screen and (min-width: $mobileBreakpoint) {
        display: flex;
        align-items: center;
        .mention {
          bottom: 2px;
          margin-left: 0;
        }
        img {
          height: 35px;
        }
      }
    }
  }
  .list-social-links {
    display: flex;
    align-items: center;
    font-size: 1em;
    line-height: 1em;
    a {
      margin-left: 1em;
      font-size: 1.4em;
    }
  }
  @media only screen and (min-width: $mobileBreakpoint) {
    padding: 20px 30px;
    margin-bottom: -30px;
    .logo {
      img {
        height: 50px;
      }
    }
    .list-social-links {
      font-size: 1.4em;
    }
  }
  small {
    font-weight: 300;
    font-size: 1em;
  }
}

.cta-footer {
  position: fixed;
  bottom: 0;
  z-index: 10;
  height: $ctaFooterHeight;
  font-weight: 400;
  padding-top: 20px;
  width: 100%;
  background-color: $ctaFooterBackground;
  text-align: center;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.7);
  @media only screen and (min-width: $mobileBreakpoint) {
    display: none;
  }
}
