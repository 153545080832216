.hide-mobile{
  display:none !important;
  @media only screen and (min-width:$mobileBreakpoint) {
    display: inherit !important;
  }
}
.no-overflow{
  overflow: hidden;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

:hover {
  transition: all .2s ease-in-out .2s;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.hidden {
  display: none;
}
