.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  top:0;
  z-index: 11;
  width:100%;
  padding: 20px;
  background-color: rgba(255,255,255,0);
  transition: background-color .25s ease-in-out;
  .logo {
    text-align: center;
    img{
      height: 28px;
      @media only screen and (min-width: 1024px) {
        height: 30px;
        margin-top: 7px;
      }
    }
  }
  .logocouleur, .logoblanc {
    position: absolute;
    top: 12px;
    left: 20px;
    @media only screen and (min-width: 1024px) {
      top: 14px;
      left: 20px;
    }
  }
  .logocouleur {
    display: none;
  }
  .logoblanc {
    display: block;
  }
  &.fixed {
    background-color: rgba(255,255,255,1);
    .logocouleur {
      display: block;
    }
    .logoblanc {
      display: none;
    }
  }
  .button-cta{
    display: none;
  }
  @media only screen and (min-width:1024px) {
    justify-content: space-between;
    height: $headerHeight;
    padding: 20px 30px;
    .logo {
      float:left;
      img{
        height: 35px;
      }
    }
    .button-cta{
      display: inherit;
      float: right;
    }
  }
  img.logomobile {
    margin: 8px 0 25px 0;
  }
}
